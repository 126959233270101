import request from '@/utils/request'

export function queryAdvList (data) {
  return request({
    url: '/activity/ad/list',
    data
  })
}

export function deleteAdvItem (data) {
  return request({
    url: '/activity/ad/del',
    data
  })
}

export function toggleAdvItem (data) {
  return request({
    url: '/activity/ad/enableStatus',
    data
  })
}

export function addAdvItem (data) {
  return request({
    url: '/activity/ad/add',
    data
  })
}

export function getAdvItem (data) {
  return request({
    url: '/activity/ad/info',
    data
  })
}

export function editAdvItem (data) {
  return request({
    url: '/activity/ad/edit',
    data
  })
}
