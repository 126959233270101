<template>
  <Layout class="AddAdver">
    <div class="content">
      <h4 class="title">
        基本信息
      </h4>
      <el-form
        ref="advForm"
        v-loading="loading"
        :model="formData"
        :rules="rules"
        label-width="110px"
        label-position="left"
      >
        <el-form-item
          label="广告名称"
          prop="adName"
        >
          <el-input
            v-model="formData.adName"
            placeholder="单行输入"
            style="width: 600px"
          />
        </el-form-item>
        <el-form-item
          label="广告ID"
          prop="adSource"
        >
          <el-input
            v-model="formData.adSource"
            placeholder="单行输入"
            style="width: 600px"
          />
        </el-form-item>
        <el-form-item
          label="广告位置"
          prop="adLocation"
        >
          <el-input
            v-model="formData.adLocation"
            placeholder="单行输入"
            style="width: 600px"
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <el-form-item
          label="奖励类型"
          prop="rewardType"
        >
          <el-radio-group v-model="formData.rewardType">
            <el-radio :label="0">
              无奖励
            </el-radio>
            <el-radio :label="1">
              固定奖励
            </el-radio>
            <el-radio :label="2">
              随机奖励
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="0!==formData.rewardType"
          label="奖品类型"
          prop="prizeType"
        >
          <el-radio-group v-model="formData.prizeType">
            <el-radio :label="1">
              金币（个）
            </el-radio>
            <el-radio :label="2">
              时长（分钟）
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="0!==formData.rewardType"
          label="奖励配置"
          prop="prizeRule"
        >
          <el-input-number
            v-if="1===formData.rewardType"
            v-model="formData.prizeNum"
            :min="1"
          />
          <el-input-number
            v-if="2===formData.rewardType"
            v-model="formData.prizeNumMin"
            :min="1"
          />
          <span v-if="2===formData.rewardType"> ~ </span>
          <el-input-number
            v-if="2===formData.rewardType"
            v-model="formData.prizeNumMax"
            :min="1"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.prizeType == 1"
          label="金币有效期"
        >
          <el-input
            v-if="formData.timeType > 0"
            v-model="formData.timeNumber"
            style="width: 120px; margin-left:0px"
            type="number"
            placeholder="请输入设置的值"
          />
          <el-select
            v-model="formData.timeType"
            placeholder="请选择时长类型"
            style="width: 150px; "
            @change="formData.timeNumber = 0"
          >
            <el-option
              v-for="(item,index) of expirationGoldTypelist"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
              {{ item.label }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="是否开启防盗刷"
          prop="isShow"
        >
          <el-switch
            v-model="formData.isShow"
            :active-value="true"
            :inactive-value="false"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <el-form-item
          v-if="true==formData.isShow"
          class="adShow"
          label="防盗刷设置"
          prop="showRule"
        >
          <el-input
            v-model="formData.showFrequency"
            maxlength="10"
          />
          <span> 次/ </span>

          <el-input
            v-model="formData.showNumber"
            placeholder="请输入内容"
            class="input-with-select"
          >
            <el-select
              slot="append"
              v-model="formData.showType"
              placeholder="请选择"
              style="width: 80px"
            >
              <el-option
                v-for="(item, index) in showTypeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item
          label="显示广告的国家"
          prop="showNations"
        >
          <div class="setting-nations">
            <el-button
              type="text"
              @click="handleShowSelectNation"
            >
              编辑国家
            </el-button>
          </div>
        </el-form-item>
        <template>
          <section
            style="width: 720px;"
            class="nationList"
            :class="{
              open: showAllNation
            }"
          >
            <el-table :data="formData.showNations">
              <el-table-column
                label="国家/地区"
                prop="nation"
                min-width="200"
              />
              <el-table-column
                label="状态"
                width="120"
              >
                展示
              </el-table-column>
            </el-table>
          </section>
          <div
            v-if="formData.showNations.length > 3"
            class="show-more"
          >
            <span
              v-if="!showAllNation"
              @click="showAllNation = true"
            >点击展开 <i class="el-icon-arrow-down" /></span>
            <span
              v-else
              @click="showAllNation = false"
            >点击收起 <i class="el-icon-arrow-up" /></span>
          </div>
        </template>
      </el-form>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
    <el-drawer
      title="编辑国家"
      :visible.sync="showSelectNation"
      direction="rtl"
      @close="handleSelectClose"
    >
      <el-table
        ref="multipleTable"
        :data="nationList"
        style="width: 100%"
        height="calc(100vh - 200px)"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          label="国家/地区"
          prop="nation"
          min-width="120"
        />
      </el-table>
      <div class="select-area">
        <span>选择要定向的国家</span>
        <div>
          <el-button
            type="text"
            @click="handleCancelSelect"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            plain
            @click="handleConfirmSelect"
          >
            确定
          </el-button>
        </div>
      </div>
    </el-drawer>
  </Layout>
</template>
<script>
import {
  addAdvItem,
  getAdvItem,
  editAdvItem
} from '@/api/advManger'
import { cloneDeep } from 'lodash'
import { getUserNationStatistics } from '@/api/electronicEntity.js'
export default {
  name: 'AddAdv',
  data () {
    const validatePrizeMinMax = (rule, value, callback) => {
      if (this.formData.rewardType === 2 && (this.formData.prizeNumMin > this.formData.prizeNumMax)) {
        callback(new Error('最小值与最大值设置不对'))
      } else {
        callback()
      }
    }
    return {
      isCreated: true,
      updateLoading: false,
      showAllNation: false,
      tempSelectionRows: [],
      showSelectNation: false,
      nationList: [],
      loading: false,
      formData: {
        id: '',
        adName: '',
        adSource: '',
        adLocation: '',
        status: 2,
        rewardType: 0,
        prizeType: 1,
        prizeNum: 1,
        prizeNumMin: 1,
        prizeNumMax: 1,
        isShow: false,
        showType: 'day',
        showNumber: 0,
        showFrequency: 0,
        showNations: [],
        timeType: 0,
        timeNumber: 0
      },
      showTypeList: [
        {
          value: 'day',
          label: '天'
        },
        {
          value: 'hour',
          label: '小时'
        },
        {
          value: 'minute',
          label: '分钟'
        }
      ],
      rules: {
        adName: [{ required: true, message: '广告名称必填' }],
        adSource: [{ required: true, message: '广告id必填' }],
        adLocation: [{ required: true, message: '广告位置必填' }],
        prizeRule: [{ required: true, trigger: 'change', validator: validatePrizeMinMax }]
      },
      expirationGoldTypelist: [
        { label: '不限制', value: 0 },
        { label: '月', value: 2 },
        { label: '天', value: 1 }
      ]
    }
  },
  created () {
    // 编辑广告
    if (this.$route.query.id && !JSON.parse(this.$route.query.isCreated)) {
      this.isCreated = false
      this.formData.id = this.$route.query.id
      getAdvItem({ id: Number(this.formData.id) }).then(res => {
        if (res.code === 200) {
          const r = res.data.info
          const pn = (() => {
            return JSON.parse(r.prize_rule)
          })()
          const show = (() => {
            return JSON.parse(r.show_rule)
          })()
          this.formData = {
            ...this.formData,
            adName: r.ad_name,
            adSource: r.ad_source,
            adLocation: r.ad_location,
            status: r.status,
            rewardType: r.reward_type,
            prizeType: r.prize_type,
            prizeNum: pn.prize_num,
            prizeNumMin: pn.prize_num_min,
            prizeNumMax: pn.prize_num_max,
            isShow: JSON.parse(show.is_show),
            showType: show.show_type,
            showNumber: show.show_number,
            showFrequency: show.show_frequency,
            showNations: JSON.parse(r.show_nations || '{}'),
            timeType: pn.timeType,
            timeNumber: pn.timeNumber
          }
          console.log(this.formData)
        }
      })
    }
  },
  mounted () {
    this.getNationList()
  },
  methods: {
    getNationList () {
      getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          this.nationList = res.data.list.map(item => {
            const { countryCode, id, nation } = item
            return { countryCode, id, nation }
          })
        }
      })
    },
    handleShowSelectNation () {
      this.showSelectNation = true
      const selectionIdList = this.formData.showNations.map(item => item.id)
      const selectionList = this.nationList.filter(item => {
        return selectionIdList.includes(item.id)
      })
      this.$nextTick(() => {
        selectionList.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      })
    },
    handleSelectClose () {
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange (selection) {
      this.tempSelectionRows = selection
    },
    handleCancelSelect () {
      this.showSelectNation = false
    },
    handleConfirmSelect () {
      this.formData.showNations = this.tempSelectionRows
      this.showSelectNation = false
    },
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      formData.showNations = JSON.stringify(formData.showNations)
      return formData
    },
    getPrizerule (rt, formData) {
      switch (rt) {
        case 0:
          return { prize_num: 0, prize_num_min: 0, prize_num_max: 0, timeType: 0, timeNumber: 0 }
        case 1:
          return { prize_num: formData.prizeNum, prize_num_min: 0, prize_num_max: 0, timeType: Number(formData.timeType), timeNumber: Number(formData.timeNumber) }
        case 2:
          return { prize_num: 0, prize_num_min: formData.prizeNumMin, prize_num_max: formData.prizeNumMax, timeType: Number(formData.timeType), timeNumber: Number(formData.timeNumber) }
      }
    },
    getShowRule (formData) {
      return { is_show: formData.isShow, show_type: formData.showType, show_number: formData.showNumber, show_frequency: formData.showFrequency }
    },
    handleSubmit () {
      this.updateLoading = true
      this.$refs.advForm.validate(valid => {
        if (valid) {
          const fmData = this.preFormatFormData()
          const f = {
            ad_name: fmData.adName,
            ad_source: fmData.adSource,
            ad_location: fmData.adLocation,
            status: fmData.status + '',
            reward_type: fmData.rewardType + '',
            prize_type: fmData.rewardType === 0 ? '0' : fmData.prizeType + '',
            prize_rule: JSON.stringify(this.getPrizerule(fmData.rewardType, fmData)),
            show_rule: JSON.stringify(this.getShowRule(fmData)),
            show_nations: fmData.showNations
          }
          console.log(f)
          if (this.formData.id && !this.isCreated) {
            // 编辑
            editAdvItem({
              ...f,
              id: this.formData.id
            }).then(res => {
              if (res.code === 200) {
                this.$notify({
                  title: '成功',
                  message: '更新成功',
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }).finally(() => {
              this.updateLoading = false
            })
          } else {
            // 新增
            addAdvItem(f).then(res => {
              if (res.code === 200) {
                this.$notify({
                  title: '成功',
                  message: '新建成功',
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }).finally(() => {
              this.updateLoading = false
            })
          }
        } else {
          this.updateLoading = false
        }
      })
    },
    handleCancel () {
      this.$router.back()
    }
  }
}
</script>
<style scoped lang="less">
.AddAdver {
    .content {
        margin: 50px 80px;
    }
    h4.title{
        margin-bottom: 50px;
    }
    .setting-nations {
        width: 600px;
        display: flex;
        justify-content: flex-end;
    }
    .select-area {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
    }
    .nationList {
        height: 180px;
        overflow: hidden;
        &.open {
        height: auto;
        overflow: auto;
        }
    }
    .show-more {
        width: 780px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        font-size: 14px;
        color: #aaa;
        >span {
        cursor: pointer;
        }
    }
    .ctrl-area{
        margin-top: 80px;
        .el-button{
        padding:10px  40px;
        }
    }
    .adShow .el-input {
      width: 100px;
    }
    .adShow .input-with-select {
      width: 200px;
    }
    .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
}
</style>
